export class FooterDetails {
    currentYear: any
    public footerNavigation: object[]
    public footerSocialNavigation: object[]
    public footerLogoNavigation: object
    public footerBottomNavigation: object[]
    public footerCountryDropdown: object[]
    public subscriptionDetails: object
    public addressComponentLeft: object
    public addressComponentRight: object
    public copyrightNotice: object

    constructor (
        currentYear: any,
        footerNavigation: object[] = [],
        footerSocialNavigation: object[] = [],
        footerLogoNavigation: object,
        footerBottomNavigation: object[] = [],
        footerCountryDropdown: object[] = [],
        subscriptionDetails: object,
        addressComponentLeft: object,
        addressComponentRight: object,
        copyrightNotice: object
    ) {
        this.currentYear = currentYear
        this.footerNavigation = footerNavigation
        this.footerSocialNavigation = footerSocialNavigation
        this.footerLogoNavigation = footerLogoNavigation
        this.footerBottomNavigation = footerBottomNavigation
        this.footerCountryDropdown = footerCountryDropdown
        this.subscriptionDetails = subscriptionDetails
        this.addressComponentLeft = addressComponentLeft
        this.addressComponentRight = addressComponentRight
        this.copyrightNotice = copyrightNotice
    }
}
