import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, Optional, PLATFORM_ID, Renderer2 } from '@angular/core'
import { DOCUMENT, Location, isPlatformBrowser } from '@angular/common'
import { ContentfulService } from './services/contentful.service'
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens'
import { ActivationEnd, NavigationEnd, Router } from '@angular/router'
import { HttpServiceService } from './services/http-service.service'
import { RoutingInitService } from './services/routing-init.service'
import { Meta } from '@angular/platform-browser'
import { HeaderDetails } from './common/models/header-details'
import { FooterDetails } from './common/models/footer-details'
import { CookieBackendService } from './services/cookie-backend.service'
import { ImageConstant, indiaDomain } from './common/utilities/constants'
import { SeoService } from './services/seo.service.component'
import { WebsiteInfo } from './common/models/website-info'
import { Response } from 'express'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  headerData = {}
  otherDetails = {}
  footerData = {}
  tickerTypeData: any
  pageAvailable: boolean = true
  isShow: boolean
  isFooterShow: boolean
  tickerTapeGaId: any
  isTickerVisible: boolean = false
  prepValue
  isPrepPage: boolean
  isNewPrepPage: boolean
  isPrepHubPage: boolean
  diagnostic_tool: boolean
  isMlppage: boolean
  isSearchResultPage: boolean
  preview = ''
  isHeader: boolean = true
  isFooter: boolean = true
  isResultPage: boolean
  isBxSearchPage: boolean = false
  private readonly response: Response
  skipLink
  headerCondition: string = 'No'
  chatValue = {}
  dynamicImagePath = ImageConstant
  uuid: any
  headerType: string
  indiaWebsite: boolean = false
  isCroScriptAdded: boolean = false
  constructor (
    @Inject(PLATFORM_ID) public platformId: any,
    private readonly contentfulService: ContentfulService,
    @Inject(DOCUMENT) public document: any,
    @Optional() @Inject(REQUEST) private readonly request: any,
    private readonly _router: Router,
    private _CookieServiceService: CookieBackendService,
    public location: Location,
    private readonly http: HttpServiceService,
    private readonly renderer: Renderer2,
    private readonly metaTagService: Meta,
    private readonly el: ElementRef,
    private readonly routingInitService: RoutingInitService,
    private readonly seoService: SeoService,
    @Optional() @Inject(RESPONSE) response: any
  ) {
    this.response = response
    const availableCountry = this.routingInitService.getcountry()
    this.isFooterShow = false
    const locationPath = this.location.path()?.split('?')[0].split('/').filter((val) => val !== '')
    if (locationPath?.length && availableCountry?.includes(locationPath[0])) {
      this.otherDetails['countryName'] = locationPath[0]
    } else {
      if (this.contentfulService.checkIndiawebsite()) {
        this.otherDetails['countryName'] = 'india'
      } else if (locationPath?.includes('prepare') || locationPath?.includes('ielts-prep-hub')) {
        this.otherDetails['countryName'] = this.routingInitService.websiteCountryCode[this._CookieServiceService.getClientCountryCode()].website
      } else {
        this.otherDetails['countryName'] = 'global'
      }
    }
    console.log('countryName=======>', this.otherDetails['countryName'], locationPath, availableCountry, this.routingInitService.websiteCountryCode[this._CookieServiceService.getClientCountryCode()])
    this.otherDetails['countryUrl'] = (this.otherDetails['countryName'] === 'global' || this.otherDetails['countryName'] === 'india') ? '' : '/' + this.otherDetails['countryName']
    this._router.events.subscribe((evt) => {
      if (evt instanceof ActivationEnd) {
        this.prepValue = this.getParameterByName('prep', this.location.path())
        if (evt.snapshot.data.isPrepPage || this.prepValue !== null) {
          this.renderer.addClass(this.document.body, 'prepare')
          if (this.document.getElementsByClassName('styles_prep').length === 0) {
            this.document.getElementById('Preptheme').setAttribute('href', 'assets/styles/styles-prep.css')
            this.document.getElementById('Preptheme').setAttribute('class', 'styles_prep')
          }
          this.isPrepPage = true
          this.renderer.removeClass(this.document.body, 'ttmrgnTopcls')
        } else {
          this.renderer.removeClass(this.document.body, 'prepare')
          this.renderer.removeClass(this.document.body, 'results')
          this.renderer.removeClass(this.document.body, this.contentfulService.getCurrentLangCode(this.otherDetails['countryName']))
          this.isPrepPage = false
          if (this.document.getElementsByClassName('styles_prep').length !== 0) {
            this.document.getElementById('Preptheme').removeAttribute('href', 'assets/styles/styles-prep.css')
            this.document.getElementById('Preptheme').removeAttribute('class', 'styles_prep')
          }
        }
        if (evt.snapshot.data.isBxSearchPage) {
          this.isBxSearchPage = true
        }
        if (evt.snapshot.data.isPrepHubPage) {
          this.isPrepHubPage = true
        } else {
          this.isPrepHubPage = false
        }
        if (evt.snapshot.data.marketingpage) {
          this.isMlppage = true
        } else {
          this.isMlppage = false
        }
        if (evt.snapshot.data.isResultPage) {
          this.isHeader = false
          this.isFooter = false
          this.isResultPage = true
        } else {
          this.isHeader = true
          this.isFooter = true
          this.isResultPage = false
        }
        if (evt.snapshot.data.diagnostic_tool) {
          this.diagnostic_tool = true
          this.headerType = 'diagnostic_header'
        } else {
          this.isHeader = true
          this.isFooter = true
          this.diagnostic_tool = false
          this.headerType = ''
        }
        if (evt.snapshot.data.isNewPrepPage) {
          this.isNewPrepPage = true
        } else {
          this.isNewPrepPage = false
        }
        if (evt.snapshot.data.diagnostic_results) {
          this.isFooter = true
          this.headerType = 'diagnostic_header'
        }
        if (this.isPrepHubPage || this.isPrepPage || this.isNewPrepPage || this.prepValue !== null) {
          this.headerCondition = 'Yes'
        }
        this.skipLink = !!evt.snapshot.data.sessionPage
        const tickerCookieValue = this._CookieServiceService.get('ticker_cookie') ? this._CookieServiceService.get('ticker_cookie') : ''
        if (tickerCookieValue === 'Yes' || (this.isPrepHubPage || this.isPrepPage || this.isNewPrepPage || this.isResultPage || this.isBxSearchPage || this.headerType === 'diagnostic_header' || (this.otherDetails['countryName'] === 'india' && this._router.url.includes('/ielts/celebrating-success')))) {
          this.isTickerVisible = false
          this.renderer.removeClass(this.document.body, 'ttmrgnTopcls')
        } else {
          this.isTickerVisible = true
        }
        let language = this.contentfulService.getCurrentLangCode(this.otherDetails['countryName'])
        if (this.isPrepPage || this.isNewPrepPage || this.isPrepHubPage || this.isSearchResultPage) {
          language = 'en-GB'
          this.renderer.removeClass(this.document.body, this.contentfulService.getCurrentLangCode(this.otherDetails['countryName']))
          this._CookieServiceService.set('currentLang', 'en-GB')
        } else {
          if (!this._CookieServiceService.get(this.otherDetails['countryName'] + '_SelectedLang')) {
            this._CookieServiceService.delete('currentLang', '/')
          } else {
            this._CookieServiceService.set('currentLang', this._CookieServiceService.get(this.otherDetails['countryName'] + '_SelectedLang'))
          }
        }
        if (this.routingInitService.getWebsiteInfo()[this.otherDetails['countryName']]?.defaultLanguage?.code !== 'en-GB') {
          // Country with other language as Default Language (eg. Vietnam, Japan)
          if (language !== 'en-GB' && this.contentfulService.isCssFileExists(language) !== '') {
            this.routingInitService.getWebsiteInfo()[this.otherDetails['countryName']].LanguageList.length > 2 && this.removeLanguage()
            this.renderer.addClass(this.document.body, language)
          } else {
            this.removeLanguage()
          }
        } else {
          // Country with English as Default Language (eg. Cambodia, Pakistan)
          if (this.contentfulService.isCssFileExists(language) !== '') {
            this.routingInitService.getWebsiteInfo()[this.otherDetails['countryName']].LanguageList.length > 2 && this.removeLanguage()
            this.renderer.addClass(this.document.body, language)
          } else {
            this.removeLanguage()
          }
        }
        if (this.contentfulService.isCssFileExists(language) !== '' && this.document.getElementsByClassName('styles_' + language).length === 0) {
          this.document.getElementById('theme').setAttribute('href', 'assets/styles/styles_' + language + '.css')
          this.document.getElementById('theme').setAttribute('class', 'styles_' + language)
        }
      }
    })
  }

  private getParameterByName (name, url): any {
    name = name.replace(/[\[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url)
    if (results == null) return null
    if (results[2].length === 0) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }
  @HostListener('window:scroll')
  checkScroll (): any {
    const scrollPosition = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0
    if (scrollPosition >= 20) {
      this.isShow = true
      this.isFooterShow = true
    } else {
      this.isShow = false
    }
  }

  ngOnInit () {
    const previewValue = this.getParameterByName('preview', this.location.path())
    if (previewValue !== null && previewValue !== '') {
      this.preview = 'true'
    }
    this.indiaWebsite = this.contentfulService.checkIndiawebsite()
    // GEO Redirection takes more time to load from global site so hiding server-side rendering to prevent the click functionality
    if (!isPlatformBrowser(this.platformId) && this.location.path()?.split('?')[0] === '' && !this.indiaWebsite) {
      this.renderer.addClass(this.document.body, 'showSpinner')
    }
    // GEO Redirection takes more time to load from global site so hiding server-side rendering to prevent the click functionality
    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          if (evt?.url !== '/results/check-your-result' && evt?.url !== '/results/table' && evt?.url !== '/results/score') {
            this.contentfulService.setCookie('previousUrl', this._router.url)
          }
          this._CookieServiceService.set('prevUrl', this.contentfulService.domainName + (this.otherDetails['countryName'] === 'global' ? '' : ('/' + this.otherDetails['countryName'])) + this._router.url)
        }
      }
    })
    if (this.contentfulService.getCurrentLangCode(this.otherDetails['countryName'])) {
      this.contentfulService.translatedData = require('../assets/i18n/' + this.contentfulService.getCurrentLangCode(this.otherDetails['countryName']) + '.json')
    }
    this.contentfulService.domainName = 'https://' + this.getBaseURL()
    this.getHeaderData()
    if (isPlatformBrowser(this.platformId)) {
      this.contentfulService.setUtminSession()
    }
  }

  addCroScript (script: any) {
    if (!this.isCroScriptAdded && script?.length) {
      const headerScriptArray = script?.split(/(<script[^>]+?>|<script>|<\/script>)/img).filter(x => { return (x.trim() !== '' && x.trim() !== '<script>' && x.trim() !== '</script>') })
      if (headerScriptArray?.length > 0 && !isPlatformBrowser(this.platformId)) {
        this.isCroScriptAdded = true
        headerScriptArray.map(croScript => {
          const script = this.document.createElement('script')
          script.innerHTML = croScript
          this.document.head.appendChild(script)
        })
      }
    }
  }
  getHeaderData () {
    this.contentfulService.headerlanguage = this.contentfulService.getCurrentLangCode(this.otherDetails['countryName'])
    const query = '?website=' + (this.indiaWebsite ? 'india' : this.otherDetails['countryName']) + '&locale=' + this.contentfulService.siteLanguage + '&preparationHeader=' + this.headerCondition
    this.http.get('HEADER_FOOTER_API', query).subscribe({
      next: (data) => {
        if (!this.contentfulService.isObjectEmpty(data)) {
          this.addCroScript(data?.croScript || [])
          const website = ('website' in data) ? data.website : {}
          this.denyGatag()
          this.addScript(data?.header)
          this.seoService.disableRobots = data?.disableRobots || ''
          let websiteDetails = {}
          if (!this.contentfulService.isObjectEmpty(website)) {
            websiteDetails = new WebsiteInfo(
              '',
              website?.websiteName || '',
              website?.live || ''
            )
          }
          if ((!this.contentfulService.isObjectEmpty(websiteDetails) && websiteDetails['status'] === 'Yes') || this.preview != '') {
          this.routingInitService.websiteStatus.next(true)
            this.pageAvailable = true
            const currentYear = data?.year ? data.year : new Date().getFullYear()
            if (data?.additionalMetaTag && data?.additionalMetaTag?.length > 0) {
              const metaTagDetails = data.additionalMetaTag.map(metaTag => {
                return { title: metaTag?.title || '', value: metaTag?.value || '', url: metaTag?.urlValue || '' }
              }).filter(notUndefined => notUndefined !== undefined)
              if (metaTagDetails.length > 0) {
                metaTagDetails.forEach(metaTag => {
                  this.metaTagService.updateTag({ name: metaTag.title, content: metaTag.value })
                })
              }
            }
            this.tickerTypeData = data?.tickerTape || ''
            this.tickerTapeGaId = data?.tickerTapeGaId?.toUpperCase() || ''
          data?.countryDropdownList?.childNavigation.map(nav => {
            if ((nav?.gaID.split(' ').join('').toLowerCase()) === (this.otherDetails['countryName'])) {
              this.metaTagService.addTags([{ property: 'og:site_name', content: 'IDP IELTS' + (nav?.gaID === 'Global' ? '' : ' ' + nav?.gaID) }])
              this.contentfulService.formalCountryname.next(nav?.gaID)
            }
          })
          const sessionMaintenance = 'underMaintenance' in data ? data.underMaintenance === 'Yes' : false
          this.contentfulService.sessionMaintenance.next(sessionMaintenance)
          const iEpopup = ('browserPopup' in data) ? data.browserPopup : ''
            if (!isPlatformBrowser(this.platformId) && (iEpopup)) {
              const currentUrl = this._router.url
              const divElement = this.document.createElement('div')
              const iemessage = '<div id="iemessage" class="ie-ppup-cont" style="display: none;"> <div class="opn_brsr_cont">  <h3>' + iEpopup + '</h3> <br /> <a class="opn_brsr_btn" href="microsoft-edge:https://www.ielts.idp.com' + currentUrl + '">OK</a></div></div>'
              divElement.innerHTML = iemessage
              this.document.body.appendChild(divElement)
            }
            const chatScript = ('chatScript' in data) ? data.chatScript : ''
            if (chatScript) {
              this.enableChatscript(chatScript)
            }
            this.contentfulService.customDimensionStatus.subscribe((datas) => {
              if (datas) {
                if (this.prepValue !== null) {
                  setTimeout(() => {
                    Array.from(this.el.nativeElement.querySelectorAll('a'))
                      .forEach((el: any) => {
                        if (el.getAttribute('no-target') === null) { el.setAttribute('target', '_blank') }
                      })
                  }, 100)
                }
                if (data?.header !== '' && isPlatformBrowser(this.platformId)) {
                   this.updatePageview()
                }
              }
            })
            this.headerData = new HeaderDetails(
              data?.ctaName || '',
              this.contentfulService.formUtmUrl(data?.ctaUrl) || '',
              data?.ctaTarget ? data.ctaTarget === 'Open in new tab' ? '_blank' : '_self' : '_blank',
              this.otherDetails['countryName'],
              data?.siteNavigation || [],
              data?.social || []
            )
            this.footerData = new FooterDetails(
              currentYear,
              data?.footerNavigation || [],
              data?.social || [],
              data?.footerLogo ? this.contentfulService.getImageDetails(data?.footerLogo, data?.footerLogo?.title || '') : {},
              data?.footerBottomNavigation || [],
              data?.countryDropdownList?.childNavigation || [],
              data?.newsletterSubscription || {},
              data?.addressComponent1 || {},
              data?.addressComponent2 || {},
              data?.copyrightNotice || {}
            )
            if (data?.smartBanner && !this.contentfulService.isObjectEmpty(data?.smartBanner)) {
              this.contentfulService.smartBannerDetails.next(data?.smartBanner)
            }
          } else {
            this.pageAvailable = false
            const nonFoundPageData = {
              metaTitle: '404 - Page Not Found',
              robots: 'noindex,follow'
            }
            this.seoService.setSiteNotFoundSeoTitle(nonFoundPageData)
            this.seoService.setSiteNotFoundSeoRobots(nonFoundPageData)
            if (!isPlatformBrowser(this.platformId)) {
              this.response.status(404)
            }
          }
        }
      },
      error: () => {
        this.pageAvailable = false
      }
    })
  }

  gotoTop (): any {
    window.scroll({
      top: 0,
      left: 0
    })
  }

  getBaseURL (): any {
    let url
    if (!isPlatformBrowser(this.platformId)) {
      url = this.request.get('origin')
    } else {
      url = this.document.location.hostname
    }
    return url
  }

  ngAfterViewInit (): void {
    let language = ''
    this._router.events.subscribe((evt) => {
      if (evt instanceof ActivationEnd) {
        this.prepValue = this.getParameterByName('prep', this.location.path())
        if (evt.snapshot.data.isPrepPage === true || this.prepValue !== null) {
          this.isPrepPage = true
        } else {
          this.isPrepPage = false
        }

        if (evt.snapshot.data.isPrepHubPage === true) {
          this.isPrepHubPage = true
        } else {
          this.isPrepHubPage = false
        }
        if (evt.snapshot.data.isSearchPage === true) {
          this.isSearchResultPage = true
        } else {
          this.isSearchResultPage = false
        }
        console.log('Navigation Event:', evt.snapshot.data)
        console.log('Flags: isPrepHubPage:', this.isPrepHubPage, 'isNewPrepPage:', this.isNewPrepPage, 'isPrepPage:', this.isPrepPage)
        if (this.isPrepHubPage || this.isPrepPage || this.isNewPrepPage || this.prepValue !== null) {
          this.headerCondition = 'Yes'
          this.getHeaderData()
        } else {
          this.headerCondition = 'No'
        }
        console.log('Header Condition:', this.headerCondition)
        language = this.contentfulService.getCurrentLangCode(this.otherDetails['countryName'])
        if (this.isPrepPage || this.isNewPrepPage || this.isPrepHubPage || this.isSearchResultPage) {
          language = 'en-GB'
          this.renderer.removeClass(this.document.body, this.contentfulService.getCurrentLangCode(this.otherDetails['countryName']))
          this._CookieServiceService.set('currentLang', 'en-GB')
        } else {
          if (!this._CookieServiceService.get(this.otherDetails['countryName'] + '_SelectedLang')) {
            this._CookieServiceService.delete('currentLang', '/')
          } else {
            this._CookieServiceService.set('currentLang', this._CookieServiceService.get(this.otherDetails['countryName'] + '_SelectedLang'))
          }
        }
        if (isPlatformBrowser(this.platformId)) {
          if ((language !== this.contentfulService.headerlanguage) && this.contentfulService.headerlanguage !== '') {
            this.headerData = {}
            this.footerData = {}
            this.contentfulService.translatedData = require('../assets/i18n/' + this.contentfulService.getCurrentLangCode(this.otherDetails['countryName']) + '.json')
            if (this.isPrepPage || this.isNewPrepPage || this.isPrepHubPage || this.isSearchResultPage) {
              language !== 'en-GB' ? this.renderer.removeClass(this.document.body, language) : this.renderer.removeClass(this.document.body, this.contentfulService.headerlanguage)
            }
            this.getHeaderData()
          }
        }
        const languageInfo = this.contentfulService.getLanguageinfo(language, this.otherDetails['countryName'])
        let htmllang = 'en'
        if (languageInfo.length > 0) {
          try {
            if (languageInfo[0].htmlTag !== '') {
              htmllang = languageInfo[0].htmlTag
            }
          } catch (e) {
          }
        }
        this.document.documentElement.lang = htmllang
      }
    }
    )
  }

  addScript (headerScript) {
    const headerScriptArray = headerScript?.split(/(<script[^>]+?>|<script>|<\/script>)/img).filter(x => { return (x.trim() !== '' && x.trim() !== '<script>' && x.trim() !== '</script>') })
    if (headerScriptArray?.length > 0 && isPlatformBrowser(this.platformId)) {
        headerScriptArray.map(header => {
          const script = this.document.createElement('script')
          script.innerHTML = header
          this.document.head.appendChild(script)
        })
    }
  }

  denyGatag () {
    if (isPlatformBrowser(this.platformId)) {
      const existingConsent = this._CookieServiceService.get('OptanonConsent')
      let existingConsentArray = []
      if (existingConsent && existingConsent?.includes('&groups=')) {
        existingConsentArray = existingConsent.split('&groups=')[1].split(',')
      }
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push('consent', 'default', {
        ad_storage: existingConsentArray?.includes('C0004:0') ? 'denied' : 'granted',
        analytics_storage: existingConsentArray?.includes('C0003:0') ? 'denied' : 'granted',
        functionality_storage: existingConsentArray?.includes('C0002:0') ? 'denied' : 'granted',
        personalization_storage: existingConsentArray?.includes('C0001:0') ? 'denied' : 'granted',
        security_storage: existingConsentArray?.includes('C0001:0') ? 'denied' : 'granted',
        ad_user_data: existingConsentArray?.includes('C0004:0') ? 'denied' : 'granted',
        ad_personalization: existingConsentArray?.includes('C0004:0') ? 'denied' : 'granted',
        wait_for_update: 500
      })
    }
  }

  updatePageview () {
      if (Object.keys(this.contentfulService.customDimensionValue).length > 0) {
        const customDimension = this.contentfulService.customDimensionValue
        let customDimensionLanguage = this.contentfulService.getCurrentLangCode(this.otherDetails['countryName'])
        if (this.isPrepPage || this.isNewPrepPage || this.isPrepHubPage || this.isSearchResultPage) {
          customDimensionLanguage = 'en-GB'
        }
        const customDimensionScript = this.document.createElement('script')
        this.contentfulService.setOrGetClientId()
        let websiteName = customDimension['websiteName'].charAt(0).toUpperCase() + customDimension['websiteName'].slice(1)
        if (this.indiaWebsite) {
          websiteName = indiaDomain
          customDimensionLanguage = 'en'
        }
        const OnetrustGroupsValue = this.contentfulService.getConsentGroupsMatch()
        let innerHTML = ''
        const assetIdPart = customDimension['assetId'] ? `, 'asset_id': '${customDimension['assetId']}'` : ''
        if (sessionStorage.getItem('customDimensionValues')) {
          this.isSearchResultPage
              ? innerHTML = `window.dataLayer = window.dataLayer || [];window.dataLayer.push({'websitename': '${websiteName}','pagename': '${customDimension['pageName']}','language':'${customDimensionLanguage}','Centre Number': '${customDimension['Centre Number']}','Candidate Number': '${customDimension['Candidate Number']}','keyword': '${customDimension['keyword']}','event':'pageview','client_id': '${this._CookieServiceService.get('client_id')}','OnetrustActiveGroups':'${OnetrustGroupsValue}'${assetIdPart}});`
              : innerHTML = `window.dataLayer = window.dataLayer || [];window.dataLayer.push({'websitename': '${websiteName}','pagename': '${customDimension['pageName']}','language':'${customDimensionLanguage}','Centre Number': '${customDimension['Centre Number']}','Candidate Number': '${customDimension['Candidate Number']}','event':'pageview','client_id': '${this._CookieServiceService.get('client_id')}','OnetrustActiveGroups':'${OnetrustGroupsValue}'${assetIdPart}});`
      } else {
          this.isSearchResultPage
              ? innerHTML = `window.dataLayer = window.dataLayer || [];window.dataLayer.push({'websitename': '${websiteName}','pagename': '${customDimension['pageName']}','language':'${customDimensionLanguage}','keyword': '${customDimension['keyword']}','event':'pageview','client_id': '${this._CookieServiceService.get('client_id')}','OnetrustActiveGroups':'${OnetrustGroupsValue}'${assetIdPart}});`
              : innerHTML = `window.dataLayer = window.dataLayer || [];window.dataLayer.push({'websitename': '${websiteName}','pagename': '${customDimension['pageName']}','language':'${customDimensionLanguage}','event':'pageview','client_id': '${this._CookieServiceService.get('client_id')}','OnetrustActiveGroups':'${OnetrustGroupsValue}'${assetIdPart}});`
      }
      customDimensionScript.innerHTML = innerHTML
        this.document.head.appendChild(customDimensionScript)
      }
  }

  removeLanguage () {
    this.routingInitService.getWebsiteInfo()[this.otherDetails['countryName']]?.LanguageList?.forEach(value => {
      if (this.document.body.classList.contains(value.code)) {
        this.renderer.removeClass(this.document.body, value.code)
      }
    })
  }
  enableChatscript (chatScript) {
    const websiteInfo = this.routingInitService.getWebsiteInfo()
    let languageArray = []
    if (this.otherDetails['countryName'] in websiteInfo) {
      let languageList = []
      languageList = languageList.concat(websiteInfo[this.otherDetails['countryName']].LanguageList)
      if (languageList.length === 0 && websiteInfo[this.otherDetails['countryName']] && Object.keys(websiteInfo[this.otherDetails['countryName']]?.defaultLanguage).length > 0) {
        languageList.unshift(websiteInfo[this.otherDetails['countryName']].defaultLanguage)
      }
      languageArray = languageList.map(language => {
        if (language.code === this.contentfulService.getCurrentLangCode(this.otherDetails['countryName']) && language.enableChat === 'Yes') {
          return language
        }
      }).filter(notUndefined => notUndefined !== undefined)
    }
    setTimeout(() => {
      if (languageArray.length > 0 && chatScript !== '') {
        const chatScriptArray = chatScript.split(/(<script[^>]+?>|<script>|<\/script>)/img).filter(x => { return (x.trim() !== '' && x.trim() !== '<script>' && x.trim() !== '</script>') })
        if (isPlatformBrowser(this.platformId)) {
          if (chatScriptArray.length > 0) {
            chatScriptArray.forEach(header => {
              const script = this.document.createElement('script')
              script.innerHTML = header
              this.document.head.appendChild(script)
            })
          }
        }
      } else {
        this.chatValue['url'] = chatScript
        if (chatScript?.includes('//line.me')) {
          this.chatValue['imageUrl'] = this.dynamicImagePath + '/assets/line-chat.svg'
        } else if (this.otherDetails['countryName'] === 'thailand') {
          this.chatValue['imageUrl'] = this.dynamicImagePath + '/IELTS_Website_Rebrand/assets/icons/free_booking_service_v3.svg'
        }
      }
    }, 1500)
  }
}
