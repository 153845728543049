<header class="header-wrapper px-48 py-16 d-flex justify-content-center align-items-center w-100"
    [ngClass]="bookNowDisable ? '': 'book-now-enabled'">
    <div class="logo flex1 d-flex align-items-center justify-content-center">
        <a class="hide enable-navigation" title="IDP IELTS" appDynamicUrl ="{{indiaHomepage}}" [routerLink] ="indiaHomepage?.includes('://') ? null : '/' + otherDetails['countryUrl']">
            <img width="160" height="28" src="{{assetPath}}/assets/home/idp_ielts_logo.svg" alt="IDP IELTS Logo" />
        </a>
        <a class="cursor-initial hide diable-navigation" title="IDP IELTS">
            <img width="160" height="28" src="{{assetPath}}/assets/home/idp_ielts_logo.svg" alt="IDP IELTS Logo" />
        </a>
    </div>
    <div class="d-flex align-items-center gap24">
        <!--Book now button-->
        <a class="right-sec book-btn button button-primary rounded-full" href="{{prepBookNow}}">
            Book now </a>
        <!--Book now button ends-->
    </div>
</header>