export const environment = {
  production: true,
  baseHref: '/',
  awsEndPointUrl: 'https://api.rebrand.ielts.dev.idp.com/v1/contentful',
  getMethodAwsEndPointUrl: 'https://api.ielts.dev.idp.com/v1/contentful/get-data-new',
  userIPUrl: 'https://api.rebrand.ielts.dev.idp.com/v1/user/geo-location',
  domainUrl: 'https://ielts.dev.idp.com',
  imageDomain: 'https://image.ielts.dev.idp.com',
  indiaDomain: 'india.ielts.dev.idp.com',
  testCenterDetailUrl: 'https://api.rebrand.ielts.dev.idp.com/v1/test-centre-detail-page',
  resultApiURL: 'https://api.rebrand.ielts.dev.idp.com/v1/externalapi/get-ielts-results',
  prepUrl: 'https://api.rebrand.ielts.dev.idp.com/v1/search/prep-search',
  googleReviewService: 'https://api.rebrand.ielts.dev.idp.com/v1/externalapi/google-service',
  X_API_KEY: process.env.X_API_KEY,
  languageVersion: '13042023',
  xApiKey: '8f2ofaLU088rBcPXcw7sV4Ej8HsSqCzN37hy8239',
  leadgenURL: 'https://api.rebrand.ielts.dev.idp.com/v1/externalapi/submit-ielts-form',
  DYNAMIC_PAGE_URL: 'https://api.rebrand.ielts.dev.idp.com/v1/dynamic-page',
  LISTING_DATA: 'https://api.rebrand.ielts.dev.idp.com/v1/listing-page',
  MLP_PAGE: 'https://api.rebrand.ielts.dev.idp.com/v1/marketing-landing',
  HOME_GET_URL: 'https://api.rebrand.ielts.dev.idp.com/v1/homepage',
  HUB_TOPIC_URL: 'https://api.rebrand.ielts.dev.idp.com/v1/landing-page',
  ARTICLE_GET_URL: 'https://api.rebrand.ielts.dev.idp.com/v1/article-detail',
  RELATED_ARTICLE: 'https://api.rebrand.ielts.dev.idp.com/v1/article-detail/related-articles',
  RO_DETAIL_URL: 'https://api.rebrand.ielts.dev.idp.com/v1/ro-page',
  RO_LIST_URL: 'https://api.rebrand.ielts.dev.idp.com/v1/externalapi/ro-list',
  search_get_Url: 'https://api.rebrand.ielts.dev.idp.com/v1/global-search',
  city_landing_get_url: 'https://api.rebrand.ielts.dev.idp.com/v1/city-landing',
  session_List_Url_v1: 'https://api.rebrand.ielts.dev.idp.com/v1/externalapi/test-dates',
  session_List_Url_v2: 'https://api.rebrand.ielts.dev.idp.com/v2/externalapi/test-dates',
  HEADER_FOOTER_API: 'https://api.rebrand.ielts.dev.idp.com/v1/header-footer',
  preparePageDataUrl: 'https://api.rebrand.ielts.dev.idp.com/v1/prepare',
  prepareSearchApi: 'https://api.rebrand.ielts.dev.idp.com/v1/prepare/search',
  clickstreamUrl: 'https://api.clickstream.dev.ielts.com/idpstats/v1/shared-analytics-clickstream-event',
  clickStreamApiKey: 'dgXeD43hSZ9r7ewm9hDXu5PzpYNvWjEb6AJQt1wH',
  bespokeFormUrl: 'https://api.rebrand.ielts.dev.idp.com/v1/submit-ielts-form',
  resultsPageDataUrl: 'https://api.rebrand.ielts.dev.idp.com/v1/results-page',
  getUserResultUrl: 'https://api.rebrand.ielts.dev.idp.com/v1/externalapi/test-results',
  indiaSiteBanner: 'https://api.rebrand.ielts.dev.idp.com/v1/externalapi/transactional-banner',
  ieltsCommunity: 'https://api.rebrand.ielts.dev.idp.com/v1/community/content',
  prepareListingUrl: 'https://api.rebrand.ielts.dev.idp.com/v1/prepareListing',
  bxSearchDomain: 'https://bxsearch.sit.ielts.com',
  munchkinId: '707-YFJ-778',
  environmentName: 'develop',
  splunkLoggerAuthKey: 'd53a2bb7-8792-4bed-b76a-3a26701c4be6'
}
