import { Component, Input, OnInit } from '@angular/core'
import { ImageConstant, indiaDomain } from '../../../common/utilities/constants'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { ContentfulService } from '../../../services/contentful.service'
import { CookieBackendService } from '../../../services/cookie-backend.service'
import { DynamicUrlDirective } from '../../../common/directives/dynamic-url.directive'

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, DynamicUrlDirective],
  selector: 'app-prep-header',
  templateUrl: './prep-header.component.html',
  styleUrls: ['./prep-header.component.css']
})
export class PrepHeaderComponent implements OnInit {
  assetPath = ImageConstant + '/IELTS_Website_Rebrand'
  bookNowDisable: boolean
  prepBookNow = ''
  indiaHomepage: any
  @Input() otherDetails
  @Input() headerData
  constructor (private contentFulService: ContentfulService, private _CookieServiceService: CookieBackendService) {}
  ngOnInit (): void {
    this.prepBookNow = this.contentFulService.getBookingUrl(this.headerData?.bookatestURL)
    if (this._CookieServiceService.get('Client_Country_Code') === 'IN' && (this.otherDetails?.countryName === 'global' || this.otherDetails?.countryName === 'india')) {
      this.indiaHomepage = 'https://' + indiaDomain
    }
  }
}
